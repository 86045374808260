<template>
  <div
    id="my-info-password"
    class="co-flex-col px-6"
  >
    <span class="text-h5 my-4">{{ title }}</span>
    <v-divider />
    <div class="co-w-full co-h40" />
  </div>
</template>

<script>
  export default {
    name: 'InfoChangePassword',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        title: '绑定手机号',
      }
    }
  }
</script>
